.custom {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255,255,255, 1);
  flex-direction: column;
  color: #000;
  z-index: 10;

}
.custom .linear-progress{
  position: relative !important;
  width: calc(100% - 200px);
  left: 0;
  top: 0
}

.upload-file-modal-container {
  min-height: 450px;
  min-width: 400px;
}